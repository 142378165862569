import { FormEvent, useCallback } from 'react';

import { CarList } from './CarList';
import { Login } from '../components/Login';
import { useBottom } from './useBottom';
import { API_BASE_URL, useCars } from './useCars';

export const Cars: React.FC = () => {
  const { connected, data, error, load, loadMore, refreshing, unauthorized } =
    useCars();
  const bottom = useBottom(loadMore);

  const logout = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      try {
        await fetch(`${API_BASE_URL}/auth/logout`, {
          credentials: 'include',
          method: 'POST',
        });
        load();
      } catch (err) {}
    },
    [load]
  );

  const onBrandClick = useCallback(
    (id: number) => async () => {
      const res = await fetch(`${API_BASE_URL}/cars/${id}/make`, {
        credentials: 'include',
      });
      if (!res.ok) {
        return;
      }
      const { make, rest }: { make?: string; rest?: string } = await res.json();
      if (make && rest) {
        const title = prompt(
          'Введите название моделей без лишнего мусора. Например, для Audi RS Q8 I введите RS Q8 без I:',
          rest
        );
        if (title) {
          await fetch(`${API_BASE_URL}/cars/${id}/brand`, {
            credentials: 'include',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ title }),
          });
        }
      }
    },
    []
  );

  return (
    <>
      {unauthorized && <Login refresh={load} />}
      {!unauthorized && (!refreshing || data.length > 0) && (
        <>
          {!error && (
            <form className="form-logout" onSubmit={logout}>
              <button type="submit">🔒</button>
            </form>
          )}
          {error ? (
            <div className="alert">{error}</div>
          ) : (
            <CarList
              cars={data}
              connected={!refreshing && connected}
              onBrandClick={onBrandClick}
            />
          )}
        </>
      )}
      <div ref={bottom.ref} />
    </>
  );
};
