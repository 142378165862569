import { useEffect, useRef, useState } from 'react';
import io, { Socket } from 'socket.io-client';

const isDev = process.env.NODE_ENV === 'development';

export const WS_BASE_URL = isDev ? 'ws://localhost:3001' : '';

export const useWs = (handler: () => void) => {
  const ws = useRef<Socket>();
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    ws.current = io(`${WS_BASE_URL}/updates`, {
      transports: ['websocket'],
    });

    ws.current.on('connect', () => {
      setConnected(true);
    });

    ws.current.on('disconnect', () => {
      setConnected(false);
    });

    return () => {
      ws.current?.close();
      ws.current = undefined;
    };
  }, []);

  useEffect(() => {
    const socket = ws.current;
    if (!socket) {
      return;
    }

    socket.on('update', handler);

    return () => {
      socket.off('update', handler);
    };
  }, [handler]);

  return { connected };
};
