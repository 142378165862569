import { Car } from './Car';
import { UiCar } from './CarTypes';

interface CarListProps {
  cars: UiCar[];
  connected: boolean;
  onBrandClick: (id: number) => () => void;
}

export const CarList: React.FC<CarListProps> = ({
  cars,
  connected,
  onBrandClick,
}) => (
  <>
    <table>
      <thead>
        <tr>
          <th className="make" style={{ opacity: connected ? 1 : 0.4 }}>
            <span className="car-icon" role="img" aria-label="car">
              🚗
            </span>
          </th>
          <th className="model">Модель</th>
          <th className="year">Год</th>
          <th className="price">Цена</th>
          <th className="change" />
          <th className="time">Время</th>
        </tr>
      </thead>
      <tbody>
        {cars.map((car) => (
          <Car key={car.id} car={car} onBrandClick={onBrandClick(car.id)} />
        ))}
      </tbody>
    </table>
    <div className="blur-top"></div>
  </>
);
