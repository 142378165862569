import { UiCar } from './CarTypes';

interface CarProps {
  car: UiCar;
  onBrandClick: () => void;
}

export const Car: React.FC<CarProps> = ({ car, onBrandClick }) => (
  <tr>
    <td className="make">
      {car.make && (
        <img
          alt={car.make}
          className="icon-make"
          src={car.display.makeImage}
          title={car.make}
        />
      )}
    </td>
    <td className="model">
      <a href={car.url} target="_blank" rel="noopener noreferrer">
        {car.title || 'Без названия'}
      </a>
      {!car.make && <button onClick={onBrandClick}>...</button>}
    </td>
    <td className="year">{car.display.year}</td>
    <td className="price">{car.display.price}</td>
    <td className="change">{car.display.priceChange}</td>
    <td className="time">
      <img
        alt={car.site}
        className="icon-site"
        src={car.display.siteImage}
        title={car.site}
      />{' '}
      {car.display.time}
    </td>
  </tr>
);
