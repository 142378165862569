import { useCallback, useEffect, useMemo, useState } from 'react';

import { ApiCar, UiCar } from './CarTypes';
import { apiCarToUiCar } from './apiCarToUiCar';
import { useWs } from './useWs';

const isDev = process.env.NODE_ENV === 'development';

export const API_BASE_URL = isDev ? 'http://localhost:3001' : '/api';

export const useCars = () => {
  const [data, setData] = useState<UiCar[]>([]);
  const [refreshing, setRefreshing] = useState(true);
  const [error, setError] = useState<string | undefined>();
  const [unauthorized, setUnauthorized] = useState(false);

  const load = useCallback(async (from?: string) => {
    setRefreshing(true);
    setError(undefined);
    try {
      const res = await fetch(
        `${API_BASE_URL}/cars${from ? `?from=${from}` : ''}`,
        {
          credentials: 'include',
        }
      );
      setUnauthorized(res.status === 401);
      if (res.status !== 200) {
        setData([]);
        throw new Error(`Request failed with status ${res.status}`);
      }
      const cars: ApiCar[] = await res.json();

      if (from) {
        if (cars.length > 0) {
          setData((v) => {
            const currentIds = v.map((c) => c.id);
            const filtered = cars
              .filter((c) => !currentIds.includes(c.id))
              .map(apiCarToUiCar);
            return filtered.length > 0 ? [...v, ...filtered] : v;
          });
        }
      } else {
        const newIds = cars.map((c) => c.id);
        setData((v) => [
          ...cars.map(apiCarToUiCar),
          ...v.filter((c) => !newIds.includes(c.id)),
        ]);
      }
    } catch (err: any) {
      setError(err.message);
    }
    setRefreshing(false);
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const loadMoreFrom = useMemo(() => data[data.length - 1]?.updatedAt, [data]);

  const loadMore = useCallback(() => {
    if (loadMoreFrom) {
      load(loadMoreFrom);
    }
  }, [load, loadMoreFrom]);

  const onUpdate = useCallback(() => {
    if (!unauthorized) {
      load();
    }
  }, [load, unauthorized]);
  const { connected } = useWs(onUpdate);

  return { connected, data, error, load, loadMore, refreshing, unauthorized };
};
