import { FormEvent, useCallback, useState } from 'react';
import { API_BASE_URL } from '../cars/useCars';
import { useMounted } from '../utils/useMounted';

interface LoginProps {
  refresh: () => void;
}

export const Login: React.FC<LoginProps> = ({ refresh }) => {
  const [values, setValues] = useState({ login: '', password: '' });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const mounted = useMounted();

  const onChange = useCallback(
    (name: 'login' | 'password') => (e: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => ({ ...v, [name]: e.target.value }));
    },
    []
  );

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setSubmitting(true);
      setError(undefined);
      fetch(`${API_BASE_URL}/auth/login`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
        .then((res) => {
          if (res.status === 403) {
            throw new Error('Неверный логин или пароль');
          }
          if (Math.floor(res.status / 100) !== 2) {
            throw new Error(`Request failed with status ${res.status}`);
          }
          if (mounted.current) {
            refresh();
          }
        })
        .catch((err) => {
          if (mounted.current) {
            setError(err.message);
          }
        })
        .then(() => {
          if (mounted.current) {
            setSubmitting(false);
          }
        });
    },
    [mounted, refresh, values]
  );

  return (
    <>
      <form className="form-login" onSubmit={submit}>
        {error && <div className="alert">{error}</div>}

        <input
          type="text"
          placeholder="Логин"
          value={values.login}
          onChange={onChange('login')}
        />
        <input
          type="password"
          placeholder="Пароль"
          value={values.password}
          onChange={onChange('password')}
        />
        <button
          type="submit"
          disabled={submitting || !values.login || !values.password}
        >
          Войти
        </button>
      </form>
    </>
  );
};
