const MS_PER_DAY = 1000 * 60 * 60 * 24;

const toDate = (d: Date) =>
  Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());

const dateDiff = (a: Date, b: Date) =>
  Math.floor((toDate(b) - toDate(a)) / MS_PER_DAY);

export const formatTime = (time: string) => {
  const now = new Date();
  const d = new Date(time);
  const days = dateDiff(d, now);
  const hm = d.toLocaleTimeString('ru-RU', {
    hour: '2-digit',
    minute: '2-digit',
  });
  if (days === 0) {
    return `${hm}`;
  }
  if (days === 1) {
    return `вчера ${hm}`;
  }
  return `${d
    .toLocaleDateString('ru-RU', { month: 'short', day: '2-digit' })
    .substr(0, 6)} ${hm}`;
};

export const formatPrice = (price: number | null) =>
  price ? `${Math.round(price / 1000)}` : '—';

export const formatYear = (year: number | null) =>
  (year && `’${year.toString().substr(-2)}`) || '—';

export const formatOldPrice = (old: number | null, price: number) => {
  if (!old || !price) {
    return '';
  }
  const delta = Math.round((price - old) / 1000);
  if (delta === 0) {
    return '';
  }
  return (delta > 0 ? '↑' : '↓') + Math.abs(delta);
};
