import { useEffect, useRef } from 'react';

export const useBottom = (onReach: () => void) => {
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onReach();
        }
      },
      { rootMargin: '400px' }
    );
    const div = bottomRef.current;
    if (div) {
      observer.observe(div);
    }
    return () => {
      if (div) {
        observer.unobserve(div);
      }
    };
  }, [bottomRef, onReach]);

  return { ref: bottomRef };
};
