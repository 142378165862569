import { ApiCar, UiCar } from './CarTypes';
import {
  formatOldPrice,
  formatPrice,
  formatTime,
  formatYear,
} from '../utils/format';

export const apiCarToUiCar = (c: ApiCar): UiCar => ({
  ...c,
  display: {
    makeImage: c.make
      ? `/images/makes/${c.make.toLocaleLowerCase().replace(/\s/g, '-')}.png`
      : undefined,
    siteImage: `/images/sites/${c.site}.png`,
    year: formatYear(c.year),
    price: formatPrice(c.price),
    priceChange: formatOldPrice(c.oldPrice, c.price),
    time: formatTime(c.updatedAt),
  },
});
